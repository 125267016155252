import { createContext } from "react";

const OverviewContext = createContext<OverviewContextValue>({
  createActive: false,
  editActive: false,
  editContext: undefined,
  setCreateActive: () => {},
  setEditActive: () => {},
  setEditContext: () => {},
});

export type OverviewContextValue = {
  createActive: boolean;
  editActive: boolean;
  editContext?: string;
  setCreateActive: Function;
  setEditActive: Function;
  setEditContext: Function;
};

OverviewContext.displayName = "OverviewContext";

export default OverviewContext;
