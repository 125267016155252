// in src/MyLayout.js
import AppBar from "@main-components/app-bar";
import AppNotification from "@main-components/notification";
import SideMenu from "@main-components/side-menu";
import ErrorPage from "@main/error";
import * as React from "react";
import BaseLayout from "./BaseLayout";

const Layout = (props: any) => (
  <BaseLayout
    {...props}
    appBar={AppBar}
    menu={SideMenu}
    notification={AppNotification}
    error={ErrorPage}
  />
);

export default Layout;
