import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import BaseAppBar from "./components/base-app-bar";
import UserMenu from "./components/user-menu";

const useStyles = makeStyles(
  (theme) => ({
    title: {
      flex: 1,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  }),
  { name: "RaAppBar" }
);

const AppBar = (props: any) => {
  const classes = useStyles(props);

  return (
    <BaseAppBar {...props} userMenu={<UserMenu />}>
      {/*   <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      /> */}
      <div></div>
    </BaseAppBar>
  );
};
export default AppBar;
