import { useTranslate } from "ra-core";
import * as React from "react";
import { FC } from "react";
import MenuItemLink from "../../components/menu-item-link";

const DashboardMenuItem: FC<DashboardMenuItemProps> = ({
  locale,
  onClick,
  ...props
}) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      onClick={onClick}
      to="/"
      primaryText={translate("ra.page.dashboard")}
      leftIcon={<i className="far fa-home fa-fw" aria-hidden="true"></i>}
      exact
      {...props}
    />
  );
};

export interface DashboardMenuItemProps {
  classes?: object;
  locale?: string;
  onClick?: () => void;
  dense?: boolean;
  sidebarIsOpen: boolean;
}

export default DashboardMenuItem;
