import { TableCellProps } from "@material-ui/core/TableCell";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Record } from "ra-core";
import * as React from "react";

const DatagridCell = React.forwardRef<HTMLTableCellElement, DatagridCellProps>(
  (
    { className, field = <div></div>, record, basePath, resource, ...rest },
    ref
  ) => (
    <td
      className={classnames(className, field.props.cellClassName)}
      align={field.props.textAlign}
      ref={ref}
      //   {...rest}
    >
      {React.cloneElement(field, {
        record,
        basePath: field.props.basePath || basePath,
        resource,
      })}
    </td>
  )
);

DatagridCell.propTypes = {
  className: PropTypes.string,
  field: PropTypes.element,
  // @ts-ignore
  record: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  basePath: PropTypes.string,
  resource: PropTypes.string,
};

export interface DatagridCellProps extends TableCellProps {
  basePath?: string;
  className?: string;
  field?: JSX.Element;
  record?: Record;
  resource?: string;
}

// wat? TypeScript looses the displayName if we don't set it explicitly
DatagridCell.displayName = "DatagridCell";

export default DatagridCell;
