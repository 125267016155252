import LogoutButton from "@main-components/logout-button";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PropTypes from "prop-types";
import { useGetIdentity, useTranslate } from "ra-core";
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import MobileMenuButton from "../mobile-menu-button";

const useStyles = makeStyles((theme) => ({
  user: {},
  userButton: {
    textTransform: "none",
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

const UserMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const translate = useTranslate();
  const { loaded, identity } = useGetIdentity();
  const classes = useStyles(props);

  const { children, label, icon, logout } = props;
  if (!logout && !children) return null;
  const open = Boolean(anchorEl);

  return (
    <ul className="navbar-nav">
      {loaded && identity?.fullName ? (
        <li className="nav-item d-none d-md-inline-flex text-nowrap">
          <a className="nav-link d-flex align-items-center">
            <i className="fas fa-user " aria-hidden="true"></i>{" "}
            <span className="d-none d-md-inline">{identity.fullName}</span>
          </a>
        </li>
      ) : (
        <div></div>
      )}
      <li className="nav-item text-nowrap signout">
        <LogoutButton />
      </li>
      <li className="nav-item text-nowrap d-md-none toggler">
        <MobileMenuButton />
      </li>
    </ul>
  );
};

UserMenu.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  logout: PropTypes.element,
  icon: PropTypes.node,
};

UserMenu.defaultProps = {
  label: "ra.auth.user_menu",
  icon: <AccountCircle />,
};

export default UserMenu;
