import SelectInput from "@main-components/inputs/select-input";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useSetLocale } from "react-admin";
const useStyles = makeStyles({
  label: { width: "10em", display: "inline-block" },
  button: { margin: "1em" },
});

const LocaleSwitcher = () => {
  const setLocale = useSetLocale();
  const classes = useStyles();

  return (
    <SelectInput
      source="language"
      label="settings.account_language"
      helperText="settings.account_language_helper_text"
      choices={[
        { id: "en", name: "general.english" },
        { id: "nl", name: "general.dutch" },
      ]}
      translateChoices={true}
      onChange={(e) => {
        setLocale(e.currentTarget.value);
      }}
    />
  );
};

export default LocaleSwitcher;
