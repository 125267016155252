import Snackbar, { SnackbarProps } from "@material-ui/core/Snackbar";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import PropTypes from "prop-types";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import {
  complete,
  getNotification,
  hideNotification,
  undo,
  undoableEventEmitter,
  useTranslate,
} from "react-admin";
import { useDispatch, useSelector } from "react-redux";

interface Props {
  type?: string;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    error: {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
    },
    warning: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.contrastText,
    },
    undo: {
      color: theme.palette.primary.light,
    },
  }),
  { name: "RaNotification" }
);

const Notification: React.FunctionComponent<
  Props & Omit<SnackbarProps, "open">
> = (props) => {
  const {
    classes: classesOverride,
    type,
    className,
    autoHideDuration,
    ...rest
  } = props;
  const [open, setOpen] = useState(false);
  const notification = useSelector(getNotification);
  const dispatch = useDispatch();
  const translate = useTranslate();
  const styles = useStyles(props);

  useEffect(() => {
    setOpen(!!notification);
  }, [notification]);

  const handleRequestClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleExited = useCallback(() => {
    if (notification && notification.undoable) {
      dispatch(complete());
      undoableEventEmitter.emit("end", { isUndo: false });
    }
    dispatch(hideNotification());
  }, [dispatch, notification]);

  const handleUndo = useCallback(() => {
    dispatch(undo());
    undoableEventEmitter.emit("end", { isUndo: true });
  }, [dispatch]);

  const severity = notification
    ? notification.type === "success" || notification.type === "info"
      ? "success"
      : notification.type === "warning"
      ? "warning"
      : "error"
    : "success";

  return (
    <Snackbar
      open={open}
      autoHideDuration={
        (notification && notification.autoHideDuration) || autoHideDuration
      }
      disableWindowBlurListener={notification && notification.undoable}
      onExited={handleExited}
      onClose={handleRequestClose}
      {...rest}
    >
      <Alert onClose={handleRequestClose} severity={severity} variant="filled">
        {notification &&
          notification.message &&
          translate(notification.message, notification.messageArgs)}
      </Alert>
    </Snackbar>
  );
};

Notification.propTypes = {
  type: PropTypes.string,
};

Notification.defaultProps = {
  type: "info",
  autoHideDuration: 5000,
};

export default Notification;
