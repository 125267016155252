import useOverviewContext from "@main-components/list/utils/use-overview-context";
import { IconButton } from "@material-ui/core";
import * as React from "react";

interface EditIconButtonProps {
  context: string;
}

const EditIconButton: React.FC<EditIconButtonProps> = (props) => {
  const { setEditActive, setEditContext, editContext } = useOverviewContext();

  const activateEdit = (context: string | null) => {
    setEditActive(!!context);

    setEditContext(context ? context : undefined);
  };

  return (
    <IconButton
      className="p-0 ml-auto"
      onClick={() => activateEdit(props.context)}
    >
      <i className="fa fa-pencil"></i>
    </IconButton>
  );
};

export default EditIconButton;
