import { useMediaQuery } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { useLogout, useTranslate } from "ra-core";
import * as React from "react";
import { FunctionComponent, ReactElement, useCallback } from "react";

interface Props {
  className?: string;
  redirectTo?: string;
  icon?: ReactElement;
}

/**
 * Logout button component, to be passed to the Admin component
 *
 * Used for the Logout Menu item in the sidebar
 */
const LogoutButton: FunctionComponent<Props> = React.forwardRef(function Logout(
  props,
  ref
) {
  const { className, redirectTo, icon, ...rest } = props;

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const translate = useTranslate();
  const logout = useLogout();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClick = useCallback(() => logout(null, redirectTo, false), [
    redirectTo,
    logout,
  ]);
  return (
    <a
      href="#"
      className="nav-link d-flex align-items-center"
      onClick={handleClick}
    >
      <i className="far fa-sign-out" aria-hidden="true"></i>{" "}
      <span className="d-none d-md-inline">{translate("ra.auth.logout")}</span>
    </a>
  );
});

export default LogoutButton;
