import { TextFieldProps } from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { InputProps, useInput, useTranslate } from "ra-core";
import * as React from "react";
import { FunctionComponent } from "react";
import { FieldTitle } from "react-admin";
import { Form, FormControlProps } from "react-bootstrap";
import BaseInput from "../BaseInput";
import sanitizeRestProps from "../sanitizeRestProps";

export type SimpleFileInputProps = FormControlProps &
  InputProps &
  Omit<TextFieldProps, "label" | "helperText" | "size">;

const SimpleFileInput: FunctionComponent<SimpleFileInputProps> = ({
  onBlur,
  onFocus,
  onChange,
  format,
  resource,
  source,
  validate,
  label,
  helperText,
  InputProps,
  size = undefined,
  className,
  inputProps,
  type = "file",
  ...rest
}) => {
  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    resource,
    source,
    type: "file",
    validate,
    ...rest,
  });

  const hasError = !!(touched && error);
  const isValid = !!(touched && !error);

  const translate = useTranslate();

  return (
    <>
      {label !== "" && label !== false && (
        <Form.Label>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </Form.Label>
      )}

      <div className="form-file">
        <BaseInput
          id={id}
          input={{
            ...input,
            onChange: (value) => {
              input.onChange(value.currentTarget.files[0]);
            },
            value: undefined,
          }}
          size={size}
          type={type}
          InputProps={InputProps}
          error={error}
          touched={touched}
          className={` form-file-input`}
          {...sanitizeRestProps(rest)}
        />
        <label className="form-file-label">
          <span className="form-file-text">
            {InputProps &&
              InputProps.placeholder &&
              (!input.value
                ? translate(InputProps.placeholder)
                : typeof input.value === "string"
                ? input.value
                : input.value.name)}
          </span>
          <span className="form-file-button">
            {translate("general.browse")}
          </span>
        </label>
      </div>

      {hasError && (
        <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
          {translate(error)}
        </Form.Control.Feedback>
      )}

      {!hasError && helperText && <Form.Text muted>{helperText}</Form.Text>}
    </>
  );
};

SimpleFileInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.object,
  resource: PropTypes.string,
  /* @ts-ignore */
  source: PropTypes.string,
};

SimpleFileInput.defaultProps = {
  options: {},
};

export default SimpleFileInput;
