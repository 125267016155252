import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as React from "react";
import { ReactNode } from "react";
import { ClassesOverride } from "react-admin";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      paddingTop: 0,
      paddingBottom: 0,
      "&:first-child": {
        paddingTop: 16,
      },
      "&:last-child": {
        paddingBottom: 16,
        [theme.breakpoints.only("xs")]: {
          paddingBottom: 70,
        },
      },
    },
  }),
  { name: "RaCardContentInner" }
);

const CardContentInner = (props: CardContentInnerProps): JSX.Element => {
  const { className, children } = props;
  const classes = useStyles(props);
  return (
    <CardContent className={classnames(classes.root, className)}>
      {children}
    </CardContent>
  );
};

CardContentInner.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  children: PropTypes.node,
};

export interface CardContentInnerProps {
  className?: string;
  children: ReactNode;
  classes?: ClassesOverride<typeof useStyles>;
}

export default CardContentInner;
