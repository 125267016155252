import Card from "@main-components/card";
import * as React from "react";

const ListWrapper = (props) => (
  <Card style={{ width: "100%" }}>
    <Card.Body>{props.children}</Card.Body>
  </Card>
);

export default ListWrapper;
