import { MenuItemProps } from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import React, {
  cloneElement,
  FC,
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
} from "react";
import { StaticContext } from "react-router";
import { NavLink, NavLinkProps } from "react-router-dom";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      color: theme.palette.text.secondary,
    },
    active: {
      color: theme.palette.text.primary,
    },
    icon: { minWidth: theme.spacing(5) },
  }),
  { name: "RaMenuItemLink" }
);

const MenuItemLink: FC<MenuItemLinkProps> = forwardRef((props, ref) => {
  const {
    classes: classesOverride,
    className,
    primaryText,
    leftIcon,
    onClick,
    sidebarIsOpen,
    ...rest
  } = props;
  const classes = useStyles(props);

  const handleMenuTap = useCallback(
    (e) => {
      onClick && onClick(e);
    },
    [onClick]
  );

  const renderMenuItem = () => {
    return (
      <li className="nav-item">
        <NavLink
          className="nav-link"
          aria-current="page"
          to={rest.to}
          activeClassName="active"
          onClick={handleMenuTap}
          {...rest}
        >
          {leftIcon &&
            cloneElement(leftIcon, {
              titleAccess: primaryText,
            })}
          {primaryText}
        </NavLink>
      </li>
    );
  };

  if (sidebarIsOpen) {
    return renderMenuItem();
  }

  return (
    <Tooltip title={primaryText} placement="right">
      {renderMenuItem()}
    </Tooltip>
  );
});

interface Props {
  leftIcon?: ReactElement;
  primaryText?: ReactNode;
  staticContext?: StaticContext;
  sidebarIsOpen: boolean;
}

export type MenuItemLinkProps = Props &
  NavLinkProps &
  MenuItemProps<"li", { button?: true }>; // HACK: https://github.com/mui-org/material-ui/issues/16245

export default MenuItemLink;
