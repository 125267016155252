import polyglotI18nProvider from "ra-i18n-polyglot";
import englishCustomMessages from "./translation-files/en.json";
import dutchCustomMessages from "./translation-files/nl.json";

const messages = {
  nl: { ra: dutchCustomMessages.app, ...dutchCustomMessages },
  en: { ra: englishCustomMessages.app, ...englishCustomMessages },
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "nl");
const TranslationProvider = i18nProvider;

export default TranslationProvider;
