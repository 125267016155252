import { TextFieldProps } from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { InputProps, useInput, useTranslate } from "ra-core";
import * as React from "react";
import { FunctionComponent } from "react";
import { FieldTitle } from "react-admin";
import { Form, FormControlProps } from "react-bootstrap";
import BaseInput from "../BaseInput";
import sanitizeRestProps from "../sanitizeRestProps";

export type TextInputProps = FormControlProps &
  InputProps &
  Omit<TextFieldProps, "label" | "helperText" | "size">;

const TextInput: FunctionComponent<TextInputProps> = ({
  onBlur,
  onFocus,
  onChange,
  format,
  resource,
  source,
  validate,
  label,
  helperText,
  InputProps,
  size = undefined,
  className,
  inputProps,
  type = "text",
  ...rest
}) => {
  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    resource,
    source,
    type: "text",
    validate,
    ...rest,
  });

  const hasError = !!(touched && error);
  const isValid = !!(touched && !error);

  const translate = useTranslate();
  return (
    <>
      {label !== "" && label !== false && (
        <Form.Label>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </Form.Label>
      )}

      <BaseInput
        id={id}
        input={input}
        size={size}
        type={type}
        InputProps={InputProps}
        error={error}
        touched={touched}
        className={className}
        {...sanitizeRestProps(rest)}
      />

      {hasError && (
        <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
          {translate(error)}
        </Form.Control.Feedback>
      )}

      {!hasError && helperText && (
        <Form.Text muted>{translate(helperText)}</Form.Text>
      )}
    </>
  );
};

TextInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.object,
  resource: PropTypes.string,
  /* @ts-ignore */
  source: PropTypes.string,
};

TextInput.defaultProps = {
  options: {},
};

export default TextInput;
