import { useContext, useState } from "react";
import OverviewContext, { OverviewContextValue } from "./overview-context";

const useOverviewContext = (props?: any): OverviewContextValue => {
  const context = useContext(OverviewContext);

  if (!context.setCreateActive) {
    if (process.env.NODE_ENV !== "production") {
    }
    return props;
  }
  return context;
};

export default useOverviewContext;

export const useOverviewState = (props?: { openCreate?: boolean }) => {
  const [createActive, setCreateActive] = useState(!!props?.openCreate);
  const [editActive, setEditActive] = useState(false);
  const [editContext, setEditContext] = useState(undefined);

  return {
    createActive,
    editActive,
    setCreateActive,
    setEditActive,
    editContext,
    setEditContext,
  };
};
