import Typography from "@material-ui/core/Typography";
import get from "lodash/get";
import PropTypes from "prop-types";
import { ChoicesProps, useChoices } from "ra-core";
import * as React from "react";
import { FC, memo } from "react";
import sanitizeRestProps from "../sanitizeRestProps";
import { fieldPropTypes, InjectedFieldProps, PublicFieldProps } from "../types";

export const SelectField: FC<SelectFieldProps> = memo<SelectFieldProps>(
  ({
    className,
    emptyText,
    source,
    record,
    choices,
    optionValue,
    optionText,
    translateChoice,
    ...rest
  }) => {
    const value = get(record, source);
    const { getChoiceText, getChoiceValue } = useChoices({
      optionText,
      optionValue,
      translateChoice,
    });

    const choice = choices.find((choice) => getChoiceValue(choice) === value);

    if (!choice) {
      return emptyText ? (
        <Typography
          component="span"
          variant="body2"
          className={className}
          {...sanitizeRestProps(rest)}
        >
          {emptyText}
        </Typography>
      ) : null;
    }

    let choiceText = getChoiceText(choice);

    return (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeRestProps(rest)}
      >
        {choiceText}
      </Typography>
    );
  }
);

SelectField.defaultProps = {
  optionText: "name",
  optionValue: "id",
  translateChoice: true,
};

SelectField.defaultProps = {
  addLabel: true,
};

SelectField.propTypes = {
  // @ts-ignore
  ...Typography.propTypes,
  ...fieldPropTypes,
  choices: PropTypes.arrayOf(PropTypes.object).isRequired,
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  optionValue: PropTypes.string,
  translateChoice: PropTypes.bool,
};

export interface SelectFieldProps
  extends ChoicesProps,
    PublicFieldProps,
    InjectedFieldProps {}

SelectField.displayName = "SelectField";

export default SelectField;
