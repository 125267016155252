import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import ContentSave from "@material-ui/icons/Save";
import classnames from "classnames";
import {
  FormContext,
  HandleSubmitWithRedirect,
  OnFailure,
  OnSuccess,
  Record,
  RedirectionSideEffect,
  SideEffectContext,
  TransformData,
  useNotify,
  useTranslate,
} from "ra-core";
import React, {
  cloneElement,
  FC,
  ReactElement,
  SyntheticEvent,
  useContext,
} from "react";
import {
  ButtonProps as RBButtonProps,
  default as RBButton,
} from "react-bootstrap/Button";
import { FormRenderProps } from "react-final-form";
import { sanitizeButtonRestProps } from "..";

const SaveButton: FC<SaveButtonProps> = (props) => {
  const {
    className,
    classes: classesOverride,
    invalid,
    label = "ra.action.save",
    disabled,
    redirect,
    saving,
    submitOnEnter,
    variant = "contained",
    icon = defaultIcon,
    onClick,
    handleSubmitWithRedirect,
    onSave,
    onSuccess,
    onFailure,
    transform,
    ...rest
  } = props;
  const classes = useStyles(props);
  const notify = useNotify();
  const translate = useTranslate();
  const { setOnSave } = useContext(FormContext);
  const { setOnSuccess, setOnFailure, setTransform } = useContext(
    SideEffectContext
  );

  const handleClick = (event) => {
    // deprecated: use onSuccess and transform instead of onSave
    if (typeof onSave === "function") {
      if (process.env.NODE_ENV !== "production") {
        console.log(
          "<SaveButton onSave> prop is deprecated, use the onSuccess prop instead."
        );
      }
      /* @ts-ignore */
      setOnSave(onSave);
    } else {
      // we reset to the Form default save function
      /* @ts-ignore */
      setOnSave();
    }
    if (onSuccess) {
      /* @ts-ignore */
      setOnSuccess(onSuccess);
    }
    if (onFailure) {
      /* @ts-ignore */
      setOnFailure(onFailure);
    }
    if (transform) {
      /* @ts-ignore */
      setTransform(transform);
    }
    if (saving) {
      // prevent double submission
      event.preventDefault();
    } else {
      if (invalid) {
        notify("ra.message.invalid_form", "warning");
      }
      // always submit form explicitly regardless of button type
      if (event) {
        event.preventDefault();
      }
      /* @ts-ignore */
      handleSubmitWithRedirect(redirect);
    }

    if (typeof onClick === "function") {
      onClick(event);
    }
  };

  const type = submitOnEnter ? "submit" : "button";
  const displayedLabel = label && translate(label, { _: label });

  return (
    <RBButton
      className={classnames(classes.button, className)}
      variant={variant}
      type={type}
      onClick={handleClick}
      color={saving ? "default" : "primary"}
      aria-label={displayedLabel}
      disabled={disabled}
      {...sanitizeButtonRestProps(rest)}
    >
      {saving ? (
        <CircularProgress
          size={18}
          thickness={2}
          className={classes.leftIcon}
        />
      ) : (
        cloneElement(icon, {
          className: classnames(classes.leftIcon, classes.icon),
        })
      )}
      {displayedLabel}
    </RBButton>
  );
};

const defaultIcon = <ContentSave />;

const useStyles = makeStyles(
  (theme) => ({
    button: {
      position: "relative",
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    icon: {
      fontSize: 18,
    },
  }),
  { name: "RaSaveButton" }
);

interface Props {
  classes?: object;
  className?: string;
  handleSubmitWithRedirect?:
    | HandleSubmitWithRedirect
    | FormRenderProps["handleSubmit"];
  // @deprecated
  onSave?: (values: object, redirect: RedirectionSideEffect) => void;
  onSuccess?: OnSuccess;
  onFailure?: OnFailure;
  transform?: TransformData;
  icon?: ReactElement;
  invalid?: boolean;
  label?: string;
  onClick?: () => void;
  disabled?: boolean;
  redirect?: RedirectionSideEffect;
  saving?: boolean;
  submitOnEnter?: boolean;
  variant?: string;
  // May be injected by Toolbar - sanitized in Button
  basePath?: string;
  handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>;
  record?: Record;
  resource?: string;
  undoable?: boolean;
}

type SaveButtonProps = Props & RBButtonProps;

SaveButton.defaultProps = {
  variant: "primary",
};
export default SaveButton;
