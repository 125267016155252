import Typography, { TypographyProps } from "@material-ui/core/Typography";
import get from "lodash/get";
import PropTypes from "prop-types";
import * as React from "react";
import { FC, memo } from "react";
import sanitizeRestProps from "../sanitizeRestProps";
import { fieldPropTypes, InjectedFieldProps, PublicFieldProps } from "../types";

const hasNumberFormat = !!(
  typeof Intl === "object" &&
  Intl &&
  typeof Intl.NumberFormat === "function"
);

export const NumberField: FC<NumberFieldProps> = memo<NumberFieldProps>(
  ({
    className,
    emptyText,
    record,
    source,
    locales,
    options,
    textAlign,
    ...rest
  }) => {
    if (!record) {
      return null;
    }
    const value = get(record, source);
    if (value == null) {
      return emptyText ? (
        <Typography
          component="span"
          variant="body2"
          className={className}
          {...sanitizeRestProps(rest)}
        >
          {emptyText}
        </Typography>
      ) : null;
    }

    return (
      <Typography
        variant="body2"
        component="span"
        className={className}
        {...sanitizeRestProps(rest)}
      >
        {hasNumberFormat ? value.toLocaleString(locales, options) : value}
      </Typography>
    );
  }
);

// what? TypeScript looses the displayName if we don't set it explicitly
NumberField.displayName = "NumberField";

NumberField.defaultProps = {
  addLabel: true,
  textAlign: "right",
};

NumberField.propTypes = {
  // @ts-ignore
  ...Typography.propTypes,
  ...fieldPropTypes,
  locales: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  options: PropTypes.object,
};

export interface NumberFieldProps
  extends PublicFieldProps,
    InjectedFieldProps,
    TypographyProps {
  locales?: string | string[];
  options?: object;
}

export default NumberField;
