import React from "react";
import NewsList from "./list";
import NewsShow from "./show";

const resource = {
  list: NewsList,
  show: NewsShow,
  icon: () => <i className="far fa-file-alt fa-fw"></i>,
};

export default resource;
