import AuthLayout from "@main-components/auth-layout";
import theme from "@theme";
import PropTypes from "prop-types";
import React, { HtmlHTMLAttributes, ReactNode, useEffect } from "react";
import { useCheckAuth } from "react-admin";
import { useHistory } from "react-router-dom";
import DefaultLoginForm from "./components/login-form";

export interface LoginProps
  extends Omit<HtmlHTMLAttributes<HTMLDivElement>, "title"> {
  children?: ReactNode;
  theme?: object;
}

const LoginPage: React.FunctionComponent<LoginProps> = (props) => {
  const { children, ...rest } = props;
  const checkAuth = useCheckAuth();
  const history = useHistory();

  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        history.push("/");
      })
      .catch(() => {
        // not authenticated, stay on the login page
      });
  }, [checkAuth, history]);

  return <AuthLayout {...rest}>{children}</AuthLayout>;
};

LoginPage.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object,
};

LoginPage.defaultProps = {
  theme: theme,
  children: <DefaultLoginForm />,
};

export default LoginPage;
