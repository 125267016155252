import { makeStyles } from "@material-ui/core/styles";
import { TextFieldProps } from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import {
  ChoicesInputProps,
  useChoices,
  useInput,
  useTranslate,
  warning,
} from "ra-core";
import * as React from "react";
import { FunctionComponent, useCallback } from "react";
import { FieldTitle } from "react-admin";
import { Form } from "react-bootstrap";
import BaseInput from "../BaseInput";

const sanitizeRestProps = ({
  addLabel,
  allowEmpty,
  alwaysOn,
  emptyValue,
  basePath,
  choices,
  className,
  component,
  crudGetMatching,
  crudGetOne,
  defaultValue,
  filter,
  filterToQuery,
  formClassName,
  initializeForm,
  initialValue,
  input,
  isRequired,
  label,
  locale,
  meta,
  onChange,
  options,
  optionValue,
  optionText,
  disableValue,
  pagination,
  perPage,
  record,
  reference,
  resource,
  setFilter,
  setPagination,
  setSort,
  sort,
  source,
  textAlign,
  translate,
  translateChoice,
  validation,
  ...rest
}: any) => rest;

const useStyles = makeStyles(
  (theme) => ({
    input: {
      minWidth: theme.spacing(20),
    },
  }),
  { name: "RaSelectInput" }
);

const SelectInput: FunctionComponent<
  ChoicesInputProps<TextFieldProps> &
    Omit<TextFieldProps, "label" | "helperText" | "size">
> = (props) => {
  const {
    allowEmpty,
    choices = [],
    classes: classesOverride,
    className,
    disableValue,
    emptyText,
    emptyValue,
    format,
    helperText,
    label,
    onBlur,
    onChange,
    onFocus,
    options,
    optionText,
    optionValue,
    parse,
    resource,
    source,
    translateChoice,
    validate,
    size = undefined,
    InputProps,
    inputProps,
    ...rest
  } = props;
  const translate = useTranslate();
  const classes = useStyles(props);

  warning(
    source === undefined,
    `If you're not wrapping the SelectInput inside a ReferenceInput, you must provide the source prop`
  );

  warning(
    choices === undefined,
    `If you're not wrapping the SelectInput inside a ReferenceInput, you must provide the choices prop`
  );

  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  });

  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    /* @ts-ignore */
    source,
    validate,

    ...rest,
  });

  const renderMenuItemOption = useCallback((choice) => getChoiceText(choice), [
    getChoiceText,
  ]);

  const hasError = !!(touched && error);
  const isValid = !!(touched && !error);

  return (
    <>
      {label !== "" && label !== false && (
        <Form.Label>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </Form.Label>
      )}

      <BaseInput
        id={id}
        input={input}
        size={size}
        type="date"
        InputProps={InputProps}
        error={error}
        touched={touched}
        as="select"
        className={`${className} form-select`}
        {...sanitizeRestProps(rest)}
      >
        <option key="p" value={""}>
          {inputProps && inputProps.placeholder}
        </option>
        {choices.map((choice) => (
          <option key={getChoiceValue(choice)} value={getChoiceValue(choice)}>
            {renderMenuItemOption(choice)}
          </option>
        ))}
      </BaseInput>

      {hasError && (
        <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
          {translate(error)}
        </Form.Control.Feedback>
      )}

      {!hasError && helperText && (
        <Form.Text muted>{translate(helperText)}</Form.Text>
      )}
    </>
  );
};

SelectInput.propTypes = {
  allowEmpty: PropTypes.bool,
  emptyText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  emptyValue: PropTypes.any,
  /* @ts-ignore */
  choices: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.object,
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  optionValue: PropTypes.string.isRequired,
  disableValue: PropTypes.string,
  resettable: PropTypes.bool,
  resource: PropTypes.string,
  source: PropTypes.string,
  translateChoice: PropTypes.bool,
};

SelectInput.defaultProps = {
  emptyText: "",
  emptyValue: "",
  options: {},
  optionText: "name",
  optionValue: "id",
  translateChoice: true,
  disableValue: "disabled",
};

export default SelectInput;
