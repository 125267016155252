import React from "react";
import { Color } from "react-bootstrap/esm/types";

const Badge = ({
  label,
  color = "primary",
  ...props
}: {
  label: string;
  color?: Color;
  [prop: string]: any;
}) => {
  return <span className={`badge bg-${color}`}>{label}</span>;
};

export default Badge;
