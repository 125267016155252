import { useMediaQuery } from "@material-ui/core";
import MuiGridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { makeStyles } from "@material-ui/core/styles";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import * as React from "react";
import { FC } from "react";
import { DatagridProps, Identifier, useListContext } from "react-admin";

type ColsProps = {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

const useStyles = makeStyles((theme) => ({
  gridList: {
    margin: 0,
  },
  tileBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)",
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: "100%",
  },
  price: {
    display: "inline",
    fontSize: "1em",
  },
  link: {
    color: "#fff",
  },
}));

const getColsForWidth = (width: Breakpoint) => {
  if (width === "xs") return 2;
  if (width === "sm") return 3;
  if (width === "md") return 4;
  if (width === "lg") return 5;
  if (width === "xl") return 5;

  return 6;
};

const times = (nbChildren: number, fn: (key: number) => any) =>
  Array.from({ length: nbChildren }, (_, key) => fn(key));

const useGridColsCount = (cols?: ColsProps) => {
  const isXs = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  const isMd = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const isLg = useMediaQuery((theme: any) => theme.breakpoints.down("lg"));
  const isXl = useMediaQuery((theme: any) => theme.breakpoints.down("xl"));

  if (isXs) return cols?.xs;
  if (isSm) return cols?.sm;
  if (isMd) return cols?.md;
  if (isLg) return cols?.lg;
  if (isXl) return cols?.xl;
};

const LoadingGridList: FC<
  GridProps & {
    nbItems?: number;
    width?: Breakpoint;
    cellHeight?: number | "auto";
    cols?: ColsProps;
  }
> = ({ cellHeight = 250, width = "lg", nbItems = 20, cols }) => {
  const classes = useStyles();
  const colsCount = useGridColsCount(cols);

  return (
    <MuiGridList
      cellHeight={cellHeight}
      cols={cols ? colsCount : getColsForWidth(width)}
      className={classes.gridList}
    >
      {" "}
      {times(nbItems, (key) => (
        <GridListTile key={key}>
          <div className={classes.placeholder} />
        </GridListTile>
      ))}
    </MuiGridList>
  );
};

const LoadedGridList: FC<
  GridProps & {
    width?: Breakpoint;
    renderGridItem: FC<any>;
    cellHeight?: number | "auto";
    cols?: ColsProps;
    ContainerComponent?: any;
  }
> = ({
  ContainerComponent,
  className,
  width = "lg",
  renderGridItem,
  cellHeight = 370,
  cols,
}) => {
  const { ids, data, basePath } = useListContext();
  const classes = useStyles();
  const colsCount = useGridColsCount(cols);

  if (!ids || !data) return null;

  if (ContainerComponent) {
    return (
      <ContainerComponent>
        {ids.map((id: Identifier) => renderGridItem(data[id]))}
      </ContainerComponent>
    );
  }

  return (
    <MuiGridList
      cellHeight={cellHeight}
      cols={cols ? colsCount : getColsForWidth(width)}
      className={`${classes.gridList} ${className ?? ""}`}
    >
      {ids.map((id: Identifier) => renderGridItem(data[id]))}
    </MuiGridList>
  );
};

interface GridProps extends DatagridProps {
  className?: string;
}

const GridList: FC<
  WithWidth & {
    renderGridItem: FC<any>;
    cellHeight?: number | "auto";
    cols?: ColsProps;
    ContainerComponent?: any;
  }
> = ({ ContainerComponent, width, renderGridItem, cellHeight, cols }) => {
  const { loaded } = useListContext();
  return loaded ? (
    <LoadedGridList
      width={width}
      renderGridItem={renderGridItem}
      cellHeight={cellHeight}
      cols={cols}
      ContainerComponent={ContainerComponent}
    />
  ) : (
    <LoadingGridList width={width} cellHeight={cellHeight} cols={cols} />
  );
};

export default withWidth()(GridList);
