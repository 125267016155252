import { Theme, useMediaQuery } from "@material-ui/core";
import * as React from "react";
import { ReduxState, setSidebarVisibility } from "react-admin";
import { useDispatch, useSelector } from "react-redux";

const MobileMenuButton = () => {
  const dispatch = useDispatch();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const open = useSelector<ReduxState, boolean>(
    (state) => state.admin.ui.sidebarOpen
  );
  const handleClose = () => dispatch(setSidebarVisibility(false));
  const toggleSidebar = () => dispatch(setSidebarVisibility(!open));

  return (
    <button
      className="navbar-toggler collapsed"
      type="button"
      data-toggle="collapse"
      data-target="#sidebarMenu"
      aria-controls="sidebarMenu"
      aria-expanded="false"
      aria-label="Toggle navigation"
      onClick={toggleSidebar}
    >
      <i className="fas fa-bars"></i>
    </button>
  );
};

export default MobileMenuButton;
