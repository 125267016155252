import { IconButton, Theme, Tooltip, useMediaQuery } from "@material-ui/core";
// import { ButtonProps as MuiButtonProps } from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { LocationDescriptor } from "history";
import { Record, RedirectionSideEffect, useTranslate } from "ra-core";
import * as React from "react";
import { FC, ReactElement, ReactNode, SyntheticEvent } from "react";
import {
  ButtonProps as RBButtonProps,
  default as RBButton,
} from "react-bootstrap/Button";
import { Color } from "react-bootstrap/esm/types";
import { NavLink } from "react-router-dom";

const Button: FC<ButtonProps> = (props) => {
  const {
    alignIcon = "left",
    children,
    classes: classesOverride,
    className,
    color,
    disabled,
    label,
    size,
    variant,
    link,
    labelParams,
    ...rest
  } = props;
  const translate = useTranslate();
  const classes = useStyles(props);
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const restProps = sanitizeButtonRestProps(rest);

  const getLinkClass = () => {
    if (variant === "link") {
      return `${variant}-`;
    }

    return variant !== "contained" ? `btn-${variant}-` : "btn-";
  };

  const renderLink = () => {
    return (
      <NavLink
        {...rest}
        className={`btn ${getLinkClass()}${props.color} ${
          className ? className : ""
        }`}
        aria-current="page"
        to={rest.to}
      >
        {alignIcon === "left" &&
          children &&
          React.cloneElement(children, {
            className: classes[`${size}Icon`],
          })}
        {label && (
          <span
            className={classnames({
              [classes.label]: alignIcon === "left",
              [classes.labelRightIcon]: alignIcon !== "left",
            })}
          >
            {translate(label, { _: label, ...(labelParams && labelParams) })}
          </span>
        )}

        {alignIcon === "right" &&
          children &&
          React.cloneElement(children, {
            className: classes[`${size}Icon`],
          })}
      </NavLink>
    );
  };

  if (isXSmall) {
    return label && !disabled ? (
      <Tooltip title={translate(label, { _: label })}>
        <IconButton
          aria-label={translate(label, { _: label })}
          className={className}
          color={color}
          {...restProps}
        >
          {children}
        </IconButton>
      </Tooltip>
    ) : (
      <IconButton
        className={className}
        color={color}
        disabled={disabled}
        {...restProps}
      >
        {children}
      </IconButton>
    );
  }

  if (link) {
    return renderLink();
  }

  return (
    <RBButton
      className={className}
      variant={`${variant && variant !== "contained" ? `${variant}-` : ""}${
        props.color
      }`}
      size={props.size}
      block={props.block}
      color={color}
      aria-label={label ? translate(label, { _: label }) : undefined}
      disabled={disabled}
      {...restProps}
      style={{
        ...restProps.style,
        textTransform: props.uppercase ? "uppercase" : null,
      }}
    >
      {alignIcon === "left" &&
        children &&
        React.cloneElement(children, {
          className: classes[`${size}Icon`],
        })}
      {label && (
        <span
          className={classnames({
            [classes.label]: alignIcon === "left",
            [classes.labelRightIcon]: alignIcon !== "left",
          })}
        >
          {translate(label, { _: label })}
        </span>
      )}
      {alignIcon === "right" &&
        children &&
        React.cloneElement(children, {
          className: classes[`${size}Icon`],
        })}
    </RBButton>
  );
};

const useStyles = makeStyles(
  {
    button: {
      display: "inline-flex",
      alignItems: "center",
    },
    label: {
      /* paddingLeft: "0.5em", */
    },
    labelRightIcon: {
      paddingRight: "0.5em",
    },
    smallIcon: {
      fontSize: 20,
    },
    mediumIcon: {
      fontSize: 22,
    },
    largeIcon: {
      fontSize: 24,
    },
  },
  { name: "RaButton" }
);

interface Props {
  alignIcon?: "left" | "right";
  children?: ReactElement;
  classes?: object;
  className?: string;
  color?: Color;
  component?: ReactNode;
  to?: string | LocationDescriptor;
  disabled?: boolean;
  label?: string;
  size?: "sm" | "lg"; // "small" | "medium" | "large";
  icon?: ReactElement;
  block?: boolean;
  uppercase?: true;
  redirect?: RedirectionSideEffect;
  variant?: "outline" | "link" | "contained";
  // May be injected by Toolbar
  basePath?: string;
  link?: boolean;
  handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>;
  handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void;
  invalid?: boolean;
  onSave?: (values: object, redirect: RedirectionSideEffect) => void;
  saving?: boolean;
  submitOnEnter?: boolean;
  pristine?: boolean;
  record?: Record;
  resource?: string;
  undoable?: boolean;
  labelParams?: any;
}

export type ButtonProps = Props &
  RBButtonProps & {
    variant?: "outline" | "link" | "contained";
  }; //  & MuiButtonProps;

export const sanitizeButtonRestProps = ({
  // The next props are injected by Toolbar
  basePath,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  onSave,
  pristine,
  record,
  redirect,
  resource,
  saving,
  submitOnEnter,
  undoable,
  ...rest
}: any) => rest;

/* Button.propTypes = {
  alignIcon: PropTypes.oneOf(["left", "right"]),
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  // size: PropTypes.oneOf(["sm", "lg"]), // PropTypes.oneOf(["small", "medium", "large"]),
}; */

Button.defaultProps = {
  color: "primary",
  variant: "contained",
  uppercase: true,
  link: false,
};

export default Button;
