import Avatar from "@material-ui/core/Avatar";
import * as React from "react";
import { FC } from "react";
import { FieldProps } from "react-admin";

interface AvatarFieldProps extends FieldProps<any> {
  className?: string;
  size?: string;
  source: string;
}

const AvatarField: FC<AvatarFieldProps> = ({
  record,
  size = "25",
  className,
  source,
}) =>
  record ? (
    <Avatar
      src={`${record[source]}?size=${size}x${size}`}
      style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
      className={className}
    />
  ) : null;

export default AvatarField;
