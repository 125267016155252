import PropTypes from "prop-types";
import { composeValidators, FieldTitle, InputProps, isRequired } from "ra-core";
import * as React from "react";
import { Children, cloneElement, FC, ReactElement } from "react";
import { Form } from "react-bootstrap";
import { useFieldArray } from "react-final-form-arrays";

const ArrayInput: FC<ArrayInputProps> = ({
  className,
  defaultValue,
  label,
  children,
  record,
  resource,
  source,
  validate,
  variant,
  margin = "dense",
  ...rest
}) => {
  const sanitizedValidate = Array.isArray(validate)
    ? composeValidators(validate)
    : validate;

  const fieldProps = useFieldArray(source, {
    initialValue: defaultValue,
    validate: sanitizedValidate,
    ...rest,
  });

  return (
    <Form.Group>
      <Form.Label htmlFor={source}>
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired(validate)}
        />
      </Form.Label>

      {cloneElement(Children.only(children), {
        ...fieldProps,
        record,
        resource,
        source,
        variant,
        margin,
      })}
    </Form.Group>
  );
};

ArrayInput.propTypes = {
  // @ts-ignore
  children: PropTypes.node,
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  resource: PropTypes.string,
  // @ts-ignore
  source: PropTypes.string,
  record: PropTypes.object,
  options: PropTypes.object,
  // @ts-ignore
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
};

ArrayInput.defaultProps = {
  options: {},
  fullWidth: true,
};

export interface ArrayInputProps extends InputProps {
  children: ReactElement;
}
export default ArrayInput;
